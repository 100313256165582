import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import colors from '../config/color';

class Homepage extends Component {
  render() {
    return (
      <Layout showHeader={true} showHero={false}>
        {/* TODO: SEO */}
        <SEO title="Here Hear 聽聽" />
        {/* <div className="search-bar-up">
          <BigSearchBar categories={data.allWordpressCategory.edges} />
          <Product title={''} edges={data.allWordpressPost.edges} />
          <div className="more-btn-content">
            <Link style={{ textAlign: 'center' }} to={'/posts/page/1'}>
              <div className="more">看更多</div>
            </Link>
          </div>
          <Category edges={data.allWordpressCategory.edges} />
        </div> */}

        <div className="content">
          <h1>聯絡我們</h1>
          <p>
            <br />
            若您也與Here Hear 聽聽有共同理想，
            <br />
            我們歡迎各式的夥伴加入。
            <br />
            <br />
            商業合作、廣告合作、異業合作邀約
            <br />
            請來信至：
            <br />
            service@herehear.com.tw
            <br />
            <br />
            再請您詳述來信原因，
            <br />
            我們會儘速回覆給您。
            <br />
          </p>
        </div>

        <style jsx>{`
          h1 {
            font-size: 24px;
            color: ${colors['dirt-brown']};
            margin: 80px 0px 20px 0px;
            display: flex;
            justify-content: center;
          }

          p {
            text-align: center;
            font-size: 18px;
            margin-bottom: 1.5em;
            display: block;
            -webkit-margin-before: 1em;
            margin-block-start: 1em;
            -webkit-margin-after: 1em;
            margin-block-end: 1em;
            line-height: 1.9;
          }

          .content {
            display: flex;
            align-items: center;
            flex-direction: column;
          }
        `}</style>
      </Layout>
    );
  }
}

export default Homepage;

export const pageQuery = graphql`
  query {
    allWordpressCategory {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    hotCategory: allWordpressCategory(
      sort: { fields: count, order: DESC }
      limit: 8
    ) {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    allWordpressPost(limit: 20) {
      edges {
        node {
          title
          slug
          categories {
            wordpress_id
            id
            name
            slug
          }
          tags {
            id
            name
            slug
          }
          jetpack_featured_media_url
        }
      }
    }
  }
`;
